"use client"

import { ReactNode, useEffect, useState } from "react"

interface Tag {
    text: string,
    badge?: ReactNode,
    iconBeforeText?: ReactNode,
    isClicked?: boolean
    className?: string,
    clickClassName?: string,
    onClick?: () => void,
    onClickBadge?: () => void,
}

export default function Tag({ text, className, badge = <></>, iconBeforeText = <></>, isClicked = false, clickClassName, onClick, onClickBadge }: Tag) {
    const [click, setClick] = useState(isClicked)

    const clickedTag = () => {
        setClick((prev) => !prev)
        if (onClick) onClick()
    }

    useEffect(() => {
        setClick(isClicked)
    }, [isClicked])

    return (
        <span onClick={clickedTag} className={`relative text-black-2 font-medium leading-[100%] p-[11px] pt-2 pb-[8px] text-13px bg-grey-1 border border-grey-1.1 rounded-sm mr-2 whitespace-nowrap ${className ?? ''} ${click ? clickClassName : ''}`}>
            {click ? (badge ? <span onClick={onClickBadge}>{badge}</span> : null) : null}
            {iconBeforeText}
            <span>{text}</span>
        </span>
    )
}