import { CSSProperties } from "react";

interface SkeletonProps {
    className: string;
    style?: CSSProperties;
    width?: string;
    isActive?: boolean;
}

export default function Skeleton({ className, width = "w-full", style, isActive = true }: SkeletonProps) {
    return (
        <div
            className={`bg-gray-300 bg-opacity-25 ${width ? width : ""} rounded-sm mb-1 ${isActive ? 'animate-flash' : ''} ${className ?? ""}`}
            style={{ ...style }}
        ></div>
    );
}
