"use client"

import React, { createContext, useContext, useState, ReactNode } from 'react';
import { db } from '@/connectors/firebase';
import { usePathname } from 'next/navigation';
import useAuth from "@/hooks/useAuth";
import { addDoc, collection, Timestamp } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';

interface Notification {
    id: string;
    title: string;
    body: any;
    fulltext?: boolean;
    action?: string;
    image?: string;
    type: "success" | "error" | "alert" | "warning"
    timestamp?: Timestamp;
}

interface NotificationContextProps {
    notifications: Notification[];
    addNotification: (notification: Omit<Notification, 'id'>) => void;
    removeNotification: (id: string) => void;
}

const NotificationContext = createContext<NotificationContextProps | undefined>(undefined);

export const useNotifications = () => {
    const context = useContext(NotificationContext);
    if (!context) {
        throw new Error('useNotifications must be used within a NotificationProvider');
    }
    return context;
};

export const NotificationProvider = ({ children }: { children: ReactNode }) => {
    const [notifications, setNotifications] = useState<Notification[]>([]);
    const { currentUser } = useAuth()
    const path = usePathname();

    const addNotification = async (notification: Omit<Notification, 'id'>) => {
        const UUID = uuidv4();
        const newNotification = { id: UUID, ...notification };
        newNotification.body = newNotification.body.toString()
        if (newNotification.body.includes("auth/network-request-failed")) {
            newNotification.body = "Your connection is weak"
            setNotifications(prevNotifications => [...prevNotifications, newNotification]);
        }
        if (notification.type == "error") {
            await addDoc(collection(db, "errorLog"), {
                ...newNotification, path: path, user: currentUser.id, timestamp: Timestamp.now()
            })
        }
        setTimeout(() => {
            removeNotification(newNotification.id);
        }, 7000);
    };

    const removeNotification = (id: string) => {
        setNotifications(prevNotifications => prevNotifications.filter(n => n.id !== id));
    };

    return (
        <NotificationContext.Provider value={{ notifications, addNotification, removeNotification }}>
            {children}
        </NotificationContext.Provider>
    );
};
