import { db } from "@/connectors/firebase"
import { User } from "@/interfaces/firebase";
import { collection, doc, getDocs, limit, query, setDoc, where } from "firebase/firestore"

export const getUsers = async () => {
    const aiUsersDocRes = await getDocs(collection(db, `users`));
    return aiUsersDocRes.docs.map(m => ({ id: m.id, ...m.data() })) as User[]
}

export const getUser = async (profileId: string): Promise<User> => {
    const aiUsersDocRef = query(
        collection(db, "users"),
        where("id", "==", profileId),
        limit(1)
    )
    const aiUserDocRes = await getDocs(aiUsersDocRef);
    const user = aiUserDocRes.docs.map(m => ({ id: m.id, ...m.data() }))
    return user[0]
}

export const updateUser = async (user: User) => {
    if (!user.id) return
    return await setDoc(doc(db, "users", user.id), user, { merge: true })
}