"use client"

import { Menu, Transition } from '@headlessui/react';
import { Fragment, RefObject, useEffect, useState } from 'react';
import { FaArrowRight, FaStar } from 'react-icons/fa6';
import { BsThreeDots } from "react-icons/bs";
import { cloneDeep, uniqueId } from 'lodash';
import useAuth from '@/hooks/useAuth';
import { sendMessageToAPI } from '@/services/api';
import { auth } from '@/connectors/firebase';
import { useRouter } from 'next/navigation';
import { useNotifications } from '@/contexts/notifyContext';
import Loading from '../Loading';
import ReactDOM from 'react-dom';

interface ComboboxDateProps {
    dealId: string;
    direction?: "right" | "left";
    positions: positions;
    reference: RefObject<HTMLButtonElement>
}

interface positions {
    top: number;
    left: number;
}

interface options {
    icon: JSX.Element;
    label: string;
}

export default function ComboboxDeal({ dealId, positions, reference, direction = "right" }: ComboboxDateProps) {

    const { currentUser, isPremiumMember } = useAuth()
    const route = useRouter();
    const { addNotification } = useNotifications()
    const [isLoading, setLoading] = useState<boolean>(false)
    const [options, setOptions] = useState<options[]>([])

    useEffect(() => {
        let optionsList = [
            {
                icon: <FaArrowRight height={9} width={9} color="#024693" style={{ transform: 'rotate(-45deg)' }} />,
                label: "Open deal"
            }
        ]
        if (currentUser && currentUser.favouriteDeals) {
            const index = currentUser.favouriteDeals.findIndex(f => f == dealId)
            if (index != -1) {
                optionsList.push(
                    {
                        icon: <FaStar height={9} width={9} color="#ff9e1b" />,
                        label: "Unfavourite"
                    }
                )
                setOptions(optionsList)
                return
            }
            optionsList.push(
                {
                    icon: <FaStar height={9} width={9} color="#024693" />,
                    label: "Favourite"
                }
            )
            setOptions(optionsList)
        }
        else {
            optionsList.push(
                {
                    icon: <FaStar height={9} width={9} color="#024693" />,
                    label: "Favourite"
                }
            )
            setOptions(optionsList)
        }
    }, [currentUser])

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 300);
    }, [options])

    const selectOption = async (option: string, e: any) => {
        stopPropagation(e)
        try {
            let favouriteDeals = (cloneDeep(currentUser.favouriteDeals) || [])
            if (option == "Favourite") {
                favouriteDeals.push(dealId)
            } else if (option == "Unfavourite") {
                const index = favouriteDeals.findIndex(f => f == dealId)
                if (index != -1) favouriteDeals.splice(index, 1)
            } else {
                goToDeal(dealId)
                return;
            }
            setLoading(true)
            const token = await auth.currentUser?.getIdToken(true) ?? ""
            await sendMessageToAPI({
                token: token,
                route: "profile/update",
                data: {
                    id: currentUser.id,
                    favouriteDeals: favouriteDeals,
                }
            })
        } catch (error: any) {
            setLoading(false)
            addNotification({ title: "Error", body: error, type: "error" })
        }
    }

    const goToDeal = (id: string) => {
        route.push(`/deals/deal?id=${id}`)
    }

    const stopPropagation = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
    }

    return (
        <Menu as="div" className="relative inline-block text-left">
            <div>
                <Menu.Button ref={reference} className={`w-[26px] h-[26px] ${isPremiumMember ? "bg-primary-5" : "bg-primary-3.3"} flex justify-center items-center`}>
                    <BsThreeDots width={12} height={3} color="#FFFFFF" />
                </Menu.Button>
            </div>
            {
                ReactDOM.createPortal(
                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items
                            className={`absolute z-10 ${isPremiumMember ? "bg-primary-1" : "bg-white"} ${direction == "right" ? "right-0" : "left-0"} mt-2 min-w-[110px] px-1 h-auto gap-2 origin-top-right divide-y divide-gray-100 rounded-sm shadow-lg ring-1 ring-black/5 focus:outline-none`}
                            style={{ top: positions.top, left: positions.left, zIndex: 50 }}
                        >
                            {
                                options.map((option, key) => {
                                    return <Fragment key={uniqueId(option.label)}>
                                        <Menu.Item>
                                            {({ active }) => (
                                                <>
                                                    {
                                                        isLoading && key != 0 ?
                                                            <div className="w-full h-[30px] flex justify-center items-center">
                                                                <Loading className="!w-6 !h-6" />
                                                            </div>
                                                            :
                                                            <button
                                                                className={`${isPremiumMember ? "text-white" : "text-primary-2"} w-full h-[30px] text-sm font-bold border-none cursor-pointer space-x-[6px] flex items-center justify-center`}
                                                                onClick={(event) => selectOption(option.label, event)}>
                                                                <div className="w-[15px] h-[15px]">{option.icon}</div>
                                                                <span>{option.label}</span>
                                                            </button>
                                                    }
                                                </>
                                            )}
                                        </Menu.Item>
                                        {
                                            (options.length - 1) != key &&
                                            <div className="my-[5px] h-px border-primary-4" />
                                        }
                                    </Fragment>
                                })
                            }
                        </Menu.Items>
                    </Transition>
                    , document.body)
            }
        </Menu>
    )
}