"use client";

import { db, auth, app, storage, } from "@/connectors/firebase"
import { useQuery } from "@tanstack/react-query";
import { onAuthStateChanged, User as FirebaseUser } from "firebase/auth";
import { User } from "@/interfaces/firebase";
import { collection, limit, onSnapshot, query, where } from "firebase/firestore";

import { useStore } from 'zustand'
import { createStore } from 'zustand/vanilla'
import { getUser } from "@/services/users";

interface Auth {
  initialized: boolean,
  isRecording: boolean,
  isRequestingOTP: boolean,
  isPremiumMember: boolean,
  isMenuOpen: boolean,
  userLoggedIn: boolean,
  user: FirebaseUser | null,
  currentUser: User,
  refreshUser: () => Promise<void>,
  setUser: (user: User) => void
  setIsRequestingOTP: (isRequestingOTP: boolean) => void
  setIsRecording: (isRecording: boolean) => void
  setIsMenuOpen: (isMenuOpen: boolean) => void
}

const initialState: Partial<Auth> = {
  isRequestingOTP: false,
  isRecording: false,
  isMenuOpen: false,
  initialized: false,
  userLoggedIn: false,
  isPremiumMember: false,
  user: null,
  currentUser: {} as any
}

const authStore = createStore<Auth>()((set, get, state) => ({
  ...initialState as Auth,
  refreshUser: async () => {
    const userProfile = await getUser(state.getState().currentUser.id)
    authStore.setState(() => ({
      currentUser: userProfile,
      isPremiumMember: userProfile.tier === "Sponsor",
    }))
  },
  setUser: (user) => {
    set((partial) => ({
      currentUser: {
        ...partial.currentUser,
        ...user,
      }
    }))
  },
  setIsRequestingOTP: (isRequestingOTP) => {
    set(() => ({
      isRequestingOTP
    }))
  },
  setIsRecording: (isRecording) => {
    set(() => ({
      isRecording
    }))
  },
  setIsMenuOpen: (isMenuOpen) => {
    set(() => ({
      isMenuOpen
    }))
  }
}))

function useAuth(): Auth
function useAuth<T>(selector: (state: Auth) => T): T
function useAuth<T>(selector?: (state: Auth) => T) {
  return useStore(authStore, selector!)
}

onAuthStateChanged(auth, async (user) => {

  let unsub = () => { }

  if (user === null) {
    unsub();
    authStore.setState(() => ({ ...initialState, initialized: true }));
    auth.signOut()
  }

  if (user) {

    const userQueryRef = query(collection(db, "users"), where("id", "==", user.uid), limit(1))

    unsub = onSnapshot(userQueryRef, async (snapshots) => {
      if (snapshots.size == 0) {
        return;
      }
      const user = snapshots.docs[0].data() as User

      authStore.setState((partial) => ({
        initialized: true,
        userLoggedIn: true,
        isPremiumMember: user.tier === "Sponsor",
        currentUser: user,
        user: partial.user
      }));

    })

  }


})

export default useAuth