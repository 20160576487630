"use client"

import { FaArrowRight, FaArrowUp, FaStar } from "react-icons/fa6";
import Tag from "../Tag";
import ComboboxDeal from "../ComboboxDeal";
import { useEffect, useRef, useState } from "react";
import useAuth from "@/hooks/useAuth";
import { formatNumberDeals } from "@/functions/format";

export interface DealCardProps {
    dealId: string
    title: string
    className?: string
    order?: string
    nOfVotes?: string
    totalValue?: number
    type?: "default" | "curated";
    tags?: string[]
    date: string
    qtdLimitShowTags?: number
    onClick?: () => void
}

export default function DealCard({ dealId, title, tags = [], date, totalValue = 0, type = "default", order, nOfVotes, className, qtdLimitShowTags = 100, onClick }: DealCardProps) {

    const { currentUser, isPremiumMember } = useAuth();
    const [position, setPosition] = useState({ top: 0, left: 0 });
    const [comboboxIsVisible, setComboboxIsVisible] = useState(true);
    const [isFavourited, setIsFavourited] = useState(false);
    const buttonRef = useRef<HTMLButtonElement>(null);

    const stopPropagation = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
        setComboboxIsVisible(!comboboxIsVisible)
        updatePosition();
    }

    const updatePosition = () => {
        if (buttonRef.current) {
            const rect = buttonRef.current.getBoundingClientRect();
            setPosition({
                top: rect.top + window.scrollY + rect.height,
                left: rect.left + window.scrollX - 83
            })
        }
    }

    useEffect(() => {
        if (currentUser && currentUser.favouriteDeals) {
            const index = currentUser.favouriteDeals.findIndex(f => f == dealId)
            if (index != -1) {
                setIsFavourited(true)
            } else {
                setIsFavourited(false)
            }
        }
    }, [currentUser])

    useEffect(() => {
        if (comboboxIsVisible) {
            updatePosition();
            window.addEventListener('scroll', updatePosition);
            window.addEventListener('resize', updatePosition);
            return () => {
                window.removeEventListener('scroll', updatePosition);
                window.removeEventListener('resize', updatePosition);
            };
        }
    }, [comboboxIsVisible]);

    return (
        <div className={`w-full flex-shrink-0 flex flex-col ${isPremiumMember ? "bg-primary-1" : "bg-primary-3.1"} rounded-sm px-[21px] py-[20px] gap-[4px] relative ${type == "default" ? (isPremiumMember ? "border border-primary-1" : "border border-primary-3.2") : "border-2 border-b-4 border-highlight"} ${className ?? ""}`} onClick={() => onClick?.()}>
            {
                isFavourited &&
                <div className="w-[35px] h-[35px] flex justify-center items-center absolute right-[35px] top-0">
                    <FaStar height={9} width={9} color="#ff9e1b" />
                </div>
            }
            <div className={`w-[35px] h-[35px] ${isPremiumMember ? "bg-primary-5" : "bg-primary-3.2"} flex justify-center items-center absolute right-0 top-0`}>
                <FaArrowRight height={12} width={12} color="#FFFFFF" style={{ transform: 'rotate(-45deg)' }} />
            </div>
            <div className="flex flex-row justify-start items-center w-full">
                <p className="font-medium text-grey-2.2 text-xs leading-3">Created on {date}</p>
            </div>
            <p className="font-bold text-grey-1.1 text-29px leading-[36px] line-clamp-3"><span>{title}</span></p>
            <div className="flex flex-wrap gap-2">
                {
                    tags.map((m, k) => {
                        if (k > qtdLimitShowTags) return
                        return <Tag key={k} text={m} className="!text-10px h-[21px] bg-grey-1 border-grey-1.1 text-primary-3.2 !font-bold flex items-center !mr-0 !py-[6px] !px-2" />
                    })
                }
            </div>
            {
                type == "curated" &&
                <div className="notranslate mt-2">
                    <p className="font-bold text-xl text-white">Total: <span className="text-[#FFB34C]">$</span>{formatNumberDeals(totalValue)}</p>
                </div>
            }
            {
                type == "default" ?
                    <div className="w-[26px] h-[26px] flex justify-center items-center absolute right-0 bottom-0" onClick={(e) => stopPropagation(e)}>
                        <ComboboxDeal reference={buttonRef} dealId={dealId} positions={position} direction="left" />
                    </div>
                    :
                    <div className="h-[26px] w-auto flex flex-row items-center absolute right-0 bottom-0 notranslate">
                        <div className={`w-[48px] h-full ${isPremiumMember ? "bg-primary-5" : "bg-primary-1"} flex flex-row justify-center items-center space-x-1`}>
                            <FaArrowUp className="text-white h-[10px] w-[10px]" />
                            <p className="font-medium text-sm text-white">{nOfVotes}</p>
                        </div>
                        <div className="w-[30px] h-full bg-highlight flex justify-center items-center">
                            <p className="font-extrabold text-xs text-primary-1">#{order}</p>
                        </div>
                    </div>
            }
        </div>
    )
}