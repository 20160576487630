import { Meeting } from "@/interfaces/firebase";
import { Timestamp } from "firebase/firestore";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

export const getCurrentTime = (date = new Date) => {
    const timeComponents = [date.getHours(), date.getMinutes()];
    return timeComponents
        .map(component => {
            const pad = (component < 10) ? '0' : '';
            return pad + component;
        })
        .join(':');
}

export const formatFirestoreTimestamp = (timestamp: Timestamp, format = "HH:mm", timeZone = "Asia/Dubai") => {
    return dayjs(timestamp.seconds * 1000).utc().tz(timeZone).format(format);
}

//USE ONLY FOR DATES WITHOUT TIME AND TIMEZONE
export const formatDate = (day: string, format = "DD/MM/YYYY") => {
    return dayjs(day).format(format);
}

//USE ONLY FOR DATES WITHOUT TIME AND TIMEZONE
export const formatDateWithTimezone = (dateWithTimezone: string, format = "DD/MM/YYYY") => {
    const originalTimezone = dateWithTimezone.slice(-6);
    return dayjs(dateWithTimezone).utcOffset(originalTimezone).format(format);
}

export const totalRecording = (meeting: Meeting) => {
    const timestampToMillis = (timestamp: Timestamp) => {
        return timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000;
    };

    const totalMillis = meeting.recording?.reduce((total, recording) => {
        const startMillis = timestampToMillis(recording.startTime);
        const endMillis = timestampToMillis(recording.endTime);
        return total + (endMillis - startMillis);
    }, 0);

    const totalSeconds = (totalMillis ?? 0) / 1000;
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = Math.floor(totalSeconds % 60);

    if (minutes > 0) {
        return `${minutes} min`;
    } else {
        return `${seconds} sec`;
    }
};

export const convertDateToTimestamp = (dateString: string): Timestamp => {
    try {
        const date = new Date(dateString);
        if (isNaN(date.getTime())) {
            throw new Error("Invalid date format");
        }
        return Timestamp.fromDate(date);
    } catch (error) {
        throw "convert date to timestamp failed.";
    }
};

export const formatToMinutes = (time: number) => {
    let totalSeconds = Math.round(time * 60);
    let minutes = Math.floor(totalSeconds / 60);
    let seconds = totalSeconds % 60;

    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = seconds.toString().padStart(2, '0');

    return `${formattedMinutes}:${formattedSeconds}`;
}

export const formatToDollar = (number: number | string): string => {
    if (typeof number === 'number') {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0
        }).format(number);
    } else if (typeof number === 'string') {
        let parsedNumber = parseFloat(number.replace(/,/g, ''));
        if (!isNaN(parsedNumber)) {
            return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0
            }).format(parsedNumber);
        } else {
            throw new Error('Invalid number format');
        }
    } else {
        throw new Error('Input must be a number or a string representing a number');
    }
}

export const getEventDay = (eventDate?: Timestamp): string => {
    if (!eventDate) return "";
    const seconds = eventDate.seconds ? eventDate.seconds : (eventDate as any)._seconds
    const start = dayjs.tz('2024-11-07', "Asia/Dubai").startOf('day');
    const end = dayjs.tz('2024-11-09', "Asia/Dubai").endOf('day');
    const date = dayjs(seconds * 1000).utc().tz("Asia/Dubai").startOf('day');

    if (date.isBefore(start) || date.isAfter(end)) {
        return "";
    }

    const dayDiff = date.diff(start, 'day');
    return `Day ${dayDiff + 1}`;
};

export const formatNumber = (value: number): string => {
    if (value >= 1_000_000_000) {
        return (value / 1_000_000_000).toFixed(0) + 'B';
    } else if (value >= 1_000_000) {
        return (value / 1_000_000).toFixed(0) + 'M';
    } else if (value >= 1_000) {
        return (value / 1_000).toFixed(0) + 'K';
    } else {
        return value.toString();
    }
}

export const formatNumberDeals = (value: number): string => {
    if (value >= 1_000_000_000) {
        return (value / 1_000_000_000).toFixed(2) + 'B';
    } else if (value >= 1_000_000) {
        const formattedValue = value / 1_000_000;
        return formattedValue % 1 === 0
            ? Math.floor(formattedValue).toString() + 'M'
            : formattedValue.toFixed(2).replace(/\.0+$/, '') + 'M';
    } else if (value >= 1_000) {
        const formattedValue = value / 1_000;
        return formattedValue % 1 === 0
            ? Math.floor(formattedValue).toString() + 'K'
            : formattedValue.toFixed(1).replace(/\.0+$/, '') + 'K';
    } else {
        return value.toString();
    }
}