import { FirebaseOptions, initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore"
import { getMessaging, onMessage } from "firebase/messaging"
import { getDownloadURL, getStorage, ref } from "firebase/storage";
require('dotenv').config()

const firebaseConfig: FirebaseOptions = {
    apiKey: process.env.NEXT_PUBLIC_API_KEY,
    authDomain: process.env.NEXT_PUBLIC_AUTH_DOMAIN,
    projectId: process.env.NEXT_PUBLIC_PROJECT_ID,
    storageBucket: process.env.NEXT_PUBLIC_STORAGE_BUCKET,
    messagingSenderId: process.env.NEXT_PUBLIC_MESSAGING_SENDER_ID,
    appId: process.env.NEXT_PUBLIC_APP_ID,
    measurementId: process.env.NEXT_PUBLIC_MEASUREMENT_ID
}

const app = initializeApp(firebaseConfig);
const auth = getAuth(app)
const db = process.env.NEXT_PUBLIC_ENVIRONMENT === "production" ? getFirestore(app) : getFirestore(app, "staging-ypo-gbs");
const storage = getStorage(app, "gs://ypo-gbs.appspot.com");

const onMessageListener = () =>
    new Promise((resolve) => {
        const messaging = getMessaging(app)
        onMessage(messaging, (payload) => {
            console.log("payload", payload)
            resolve(payload);
        });
    });

const getDownloadURLWithToken = async (filePath: string) => {
    const storageRef = ref(storage, filePath);
    try {
        const url = await getDownloadURL(storageRef);
        console.log('File available at', url);
        return url;
    } catch (error) {
        console.error('Error getting download URL', error);
        throw error;
    }
};

export { app, auth, db, storage, onMessageListener, getDownloadURLWithToken }