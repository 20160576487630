import Image from "next/image"
import blue from "../../../public/logo/ypo-logo-blue.png"
import darkBlue from "../../../public/logo/ypo-logo-dark-blue.png"
import white from "../../../public/logo/ypo-logo-white.png"

interface Logo {
    color: keyof typeof FromToLogos,
    className?: string,
    height?: number,
    width?: number,
}

const FromToLogos = {
    'blue': blue,
    'darkBlue': darkBlue,
    'white': white,
}

export default function Logo({ className, color = 'white', height = 79, width = 91 }: Logo) {
    return (
        <Image src={FromToLogos[color]} className={className ?? ""} alt={color} height={height} width={width} />
    )
}