require('dotenv').config()

interface sendMessageToAPIProps {
	token: string,
	route: string,
	data: object,
	contentType?: string,
	returnJson?: boolean
}

export const sendMessageToAPI = async ({ token, route, data, contentType = "application/json", returnJson = false }: sendMessageToAPIProps): Promise<any> => {
	try {
		const response = await fetch(`${process.env.NEXT_PUBLIC_API_LINK}/${route}`, {
			method: "POST",
			headers: {
				"authorization": `Bearer ${token}`,
				"content-type": contentType,
			},
			body: JSON.stringify(data)
		});
		if (route == "voice/TTS") {
			return await response.blob()
		}
		if (route == "voice/STT") {
			return
		}
		if (returnJson || route == "auth/otp/check" || route == "auth/otp/request") {
			const res = await response.json()
			return res
		}
	}
	catch (e: any) {
		throw new Error(e)
	}
}